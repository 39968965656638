<template>
  <div class="join">
    <Head :classA="classANum" headBg="1"></Head>
    <div class="main">
      <div class="bannerWrap">
        <img class="newsBanner" src="@/assets/images/join/joinBanner.png" />
        <img class="newsIcon" src="@/assets/images/join/joinIcon.png" />
      </div>
      <div class="outer">
        <!-- <div class="left">
          <ul id="headUl">
            <li class="active">人才发展</li>
            <li>官方代理招募</li>
          </ul>
        </div> -->
        <div class="right" style="width: 100% !important">
          <dl id="talentId" class="talentWrap">
            <!-- <dt>人才发展</dt> -->
            <dd>
              <div class="listWrap">
                <div class="listText personText">
                  <p>
                    致医健康秉持“精选、尊重、成就”的人才发展理念，坚持企业与员工共同发展的人才培养目标，在人力资源管理领域深度贯彻“专业、创新、激情”的企业文化，精心选择与企业相契合的优秀人才，为员工提供干事创业、公平竞争的舞台，能者上、平者让、庸者下，同时构建完善的晋升机制，不断完善员工职业规划。
                  </p>
                </div>
                <img src="@/assets/images/join/joinTop.png" />
              </div>
            </dd>
            <dd>
              <h5>薪酬福利</h5>
              <ul class="ulList benefitsUl">
                <li>
                  <h6 class="bgYellow">有竞争力的薪酬</h6>
                  <div class="ulTextWrap">
                    <p>基本工资</p>
                    <p>绩效奖金</p>
                    <p>高额提成</p>
                    <p>通讯、交通补助</p>
                  </div>
                </li>
                <li>
                  <h6 class="bgOrange">完善的生活保障</h6>
                  <div class="ulTextWrap">
                    <p>社会保险（养老、医疗、工伤、失业、生育）</p>
                    <p>住房公积金</p>
                    <p>工作居住证办理</p>
                    <p>留学生落户资格</p>
                  </div>
                </li>
                <li>
                  <h6 class="bgRed">贴心的员工福利</h6>
                  <div class="ulTextWrap">
                    <p>
                      特殊节日礼物、员工宿舍、企业食堂、车费报销、定期体检、户外团建、球类俱乐部、运动比赛等
                    </p>
                  </div>
                </li>
                <li>
                  <h6 class="bgGreen">在招职位详情</h6>
                  <div class="ulTextWrap bossWrap">
                    <a
                      href="https://www.zhipin.com/gongsi/d1b4f59af787a1780nR639q1.html?ka=search_rcmd_company_d1b4f59af787a1780nR639q1_custompage"
                      target="_blank"
                    >
                      <span>立即前往BOSS直聘</span>
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </dl>
          <!-- <dl id="officialId" class="officialWrap">
            <dt>官方代理招募</dt>
            <dd>
              <div class="listWrap">
                <div class="listText">
                  <h4>包容开放，共建共赢</h4>
                  <p>
                    致医健康致力于构建一支肯努力、敢拼搏、有梦想的代理团队，只要你有实力、有激情、充满野心，并对医疗互联网高度认可，致医健康将为您提供最优质的发展平台！欢迎您的加盟！
                  </p>
                </div>
                <img src="@/assets/images/join/joinBot.png" />
              </div>
            </dd>
            <dd>
              <h5>加盟要求</h5>
              <ul class="ulList demandsUl">
                <li>
                  <h6 class="bgYellow">区域资源</h6>
                  <p>
                    有良好的区域资源，能对接各地市县区医保、药监、医疗协会、医生协会资源。
                  </p>
                </li>
                <li>
                  <h6 class="bgOrange">终端资源</h6>
                  <p>有区域内丰富的第三终端资源，能实现用户群的快速扩张。</p>
                </li>
                <li>
                  <h6 class="bgRed">行业认同度</h6>
                  <p>
                    认同致医健康发展前景，对医药、医疗互联网及信息化服务项目高度认可，有意向长期合作。
                  </p>
                </li>
              </ul>
            </dd>
            <dd>
              <h5>公司支持</h5>
              <ul class="ulList supportUl">
                <li>
                  <h6 class="bgYellow">专业培训</h6>
                  <p>公司提供专业的培训团队</p>
                  <p>指导产品操作</p>
                </li>
                <li>
                  <h6 class="bgOrange">线上支持</h6>
                  <p>公司提供线上支持与服务</p>
                  <p>7*24小时客服</p>
                </li>
                <li>
                  <h6 class="bgRed">内部政策</h6>
                  <p>公司提供周边产品及药品优惠</p>
                  <p>辅助销售</p>
                </li>
              </ul>
            </dd>
            <dd class="joinType">
              <h5>加盟方式</h5>
              <ul class="ulList joinWayUl">
                <li>
                  <p>拨打全国统一客服电话</p>
                  <p>
                    <strong>400-620-9299</strong>
                  </p>
                </li>
                <li>
                  <p>添加微信或直接拨打电话</p>
                  <p>
                    <strong>185-1559-7288</strong>
                  </p>
                </li>
                <li>
                  <p>扫码关注“致医云诊室”公众号</p>
                  <p>
                    <strong>获取在线人工答疑</strong>
                  </p>
                </li>
              </ul>
            </dd>
          </dl>
          <div @click="showJoin" class="businessWrap">我要加盟</div> -->
        </div>
      </div>
    </div>
    <Foot></Foot>
    <JoinBox
      ref="joinBox"
      companyText="企业名称（选填）"
      type="0"
      title="申请加盟官方代理"
    ></JoinBox>
  </div>
</template>
<style scoped>
@import '../assets/style/leftNav.css';
</style>
<style lang="scss" scoped>
.join {
  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;
    .outer {
      .right {
        dl {
          dt {
            font-size: 20px;
            color: #333;
            text-align: left;
            padding: 30px 0 14px 20px;
            font-weight: bold;
          }
          dd {
            .listWrap {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              background: #fff;
              .listText {
                padding: 20px 30px;
                flex-grow: 1;
                h4 {
                  font-size: 16px;
                  color: #333;
                  text-align: left;
                  padding-bottom: 10px;
                  font-weight: bold;
                }

                p {
                  font-size: 14px;
                  color: #666;
                  text-align: left;
                  line-height: 22px;
                }
              }
              .personText {
                p {
                  line-height: 28px;
                  text-align: justify;
                }
              }
              img {
                width: 100%;
                max-width: 344px;
              }
            }
            h5 {
              font-size: 16px;
              color: rgba(51, 51, 51, 1);
              line-height: 22px;
              padding: 20px 0 14px 20px;
              text-align: left;
            }
            .ulList {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              li {
                background: #fff;
                margin-bottom: 20px;
                h6 {
                  height: 80px;
                  line-height: 80px;
                  border-radius: 4px 4px 0px 0px;
                  font-size: 26px;
                  color: rgba(255, 255, 255, 1);
                  text-align: left;
                  padding-left: 30px;
                  margin-bottom: 30px;
                  font-weight: bold;
                }
                p {
                  font-size: 14px;
                  color: #666;
                  text-align: left;
                  line-height: 28px;
                  padding: 0 30px;
                  text-align: justify;
                }
              }
            }
          }
        }
        .talentWrap {
          dt {
            padding: 0 0 14px 20px;
          }
          dd {
            .listWrap {
              p {
                color: #333;
              }
            }
            .benefitsUl {
              li {
                width: 49%;
                height: 266px;
                .bossWrap {
                  padding-top: 30px;
                  a {
                    display: inline-block;
                  }
                  span {
                    display: inline-block;
                    width: 200px;
                    height: 60px;
                    background: linear-gradient(
                      270deg,
                      rgba(0, 200, 183, 1) 0%,
                      rgba(24, 231, 194, 1) 100%
                    );
                    border-radius: 4px;
                    line-height: 64px;
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba(255, 255, 255, 1);
                  }
                }
              }
            }
          }
        }
        .officialWrap {
          dd {
            .demandsUl,
            .supportUl {
              li {
                width: 32.5%;
                height: 210px;
              }
            }
            .joinWayUl {
              li {
                width: 32.5%;
                padding: 20px 30px 20px 0;
                box-sizing: border-box;
                strong {
                  font-weight: bold;
                }
              }
              li:last-child {
                strong {
                  font-size: 14px;
                }
              }
            }
          }
          .joinType {
            strong {
              font-weight: bold;
              font-size: 16px;
              color: #333;
            }
          }
        }
        .businessWrap {
          width: 200px;
          height: 60px;
          background: linear-gradient(
            90deg,
            rgba(92, 41, 246, 1) 0%,
            rgba(34, 19, 222, 1) 100%
          );
          border-radius: 4px;
          line-height: 60px;
          margin: 30px auto 20px auto;
          font-size: 18px;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
        }
      }
      .bgYellow {
        background: linear-gradient(
          90deg,
          rgba(98, 85, 255, 1) 0%,
          rgba(159, 124, 254, 1) 100%
        );
      }
      .bgOrange {
        background: linear-gradient(
          90deg,
          rgba(255, 122, 47, 1) 0%,
          rgba(255, 162, 50, 1) 100%
        );
      }
      .bgRed {
        background: linear-gradient(
          90deg,
          rgba(255, 87, 85, 1) 0%,
          rgba(254, 135, 124, 1) 100%
        );
      }
      .bgGreen {
        background: linear-gradient(
          90deg,
          rgba(0, 200, 183, 1) 0%,
          rgba(24, 231, 194, 1) 100%
        );
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .main {
      width: 90%;
    }
  }
  @media screen and (max-width: 668px) {
    .main .outer .right dl dd .listWrap {
      justify-content: center;
      flex-wrap: wrap;
    }
    .main .outer .right .officialWrap dd .demandsUl li,
    .main .outer .right .officialWrap dd .supportUl li {
      height: auto;
      padding-bottom: 20px;
    }
    .main .outer .right .talentWrap dd .benefitsUl li,
    .main .outer .right .officialWrap dd .demandsUl li,
    .main .outer .right .officialWrap dd .supportUl li,
    .main .outer .right .officialWrap dd .joinWayUl li {
      width: 100%;
    }
  }
}
</style>
<script>
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'
import JoinBox from '@/components/common/JoinBox'
import '../assets/js/leftNav.js'

export default {
  name: 'news',
  components: {
    Head,
    Foot,
    JoinBox
  },
  data () {
    return {
      classANum: '5'
    }
  },
  methods: {
    showJoin () {
      this.$refs.joinBox.isShowDialog = true
    },
    paramFun () {
      if (this.$route.params.nameId) {
        var h = document.getElementById('officialId').offsetTop - 90
        document.documentElement.scrollTop = h
      }
    }
  },
  mounted () {
    this.paramFun()
  }
}
</script>
